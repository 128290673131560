.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  color: "#EF9667" !important;
}

a:hover {
  color: "#EF9667" !important;
}

.custom-link {
  color: #EF9667;
  text-decoration: none;
  padding: 10px;
}

/* Changing the color */
.custom-link:hover {
  color: #EF9667;
}

.nav-link {
  color: #404040;
  font-size: 20px; /* Increased font size for better spacing */
  padding: 10px; /* Added padding for increased spacing */
  /* transition: transform 0.3s ease; */
}

/* .nav-link:hover {
  transform: scale(1.1);
} */

.active {
  color: #000000 !important;
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
  text-decoration: none;
}

.main_logo {
  transition: transform 0.3s ease;
}

.main_logo:hover {
  transform: scale(1.1);
}
article {
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px; /* Increased padding */
}

article:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}
input[type="password"] {
  border-radius: 15px;
  border-width: 1px;
  padding-left: 10px; /* Increased left padding */
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}


button {
  border-width: 1px;
  margin-top: 10px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #EF9667;
}


footer{
  background-color: #282c34;
  color: white;
  padding: 10px;
}
